import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Section from '../utils/Section';

const useStyles = makeStyles((theme) => {
  return {
    paragraph: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(4),
    },
  };
});

function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <Section>
      <Typography variant="h5">Privacy policy</Typography>

      <Typography className={classes.paragraph} variant="body1">
        This website uses Web Storage to store data that is used to enhance the user experience and enable Sorvimo Tools to collect usage statistics.
      </Typography>

      <Typography className={classes.paragraph} variant="body1">
        This site also uses Cookies for Google Analytics. This service is used to collect anonymous usage statistics. The collected data is used to
        better understand how our visitors use this site. For example the following pieces of data are collected from the site users:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">Visited pages</Typography>
        </li>
        <li>
          <Typography variant="body1">Operating system and browser</Typography>
        </li>
        <li>
          <Typography variant="body1">Screen resolution</Typography>
        </li>
      </ul>

      <Typography className={classes.paragraph} variant="body1">
        Data is not shared to third parties except for Cookies with Google Analytics. As data is generally used for statistical purposes, it is 
        stored permanently. You have right to check your data and request it to be updated or removed. For questions regarding data or privacy, please 
        contact us at <a href="mailto:sorvimo@sorvimo.com">sorvimo@sorvimo.com</a>.
      </Typography>

      <Typography className={classes.subtitle} variant="h6">Sorvimo Tools</Typography>

      <Typography className={classes.paragraph} variant="body1">
        Sorvimo Tools collect anonymous usage data that is used for software development purposes. The exact collected data depends on the tool, but
        generally the following kind of data is collected:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">Performed calculations</Typography>
        </li>
        <li>
          <Typography variant="body1">Time spent in each tool</Typography>
        </li>
        <li>
          <Typography variant="body1">Information about any design errors</Typography>
        </li>
      </ul>

      <Typography className={classes.subtitle} variant="h6">Contact form</Typography>

      <Typography className={classes.paragraph} variant="body1">
        Following data is collected from the contact form to enable us to respond to your message:
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">Name</Typography>
        </li>
        <li>
          <Typography variant="body1">Email address</Typography>
        </li>
        <li>
          <Typography variant="body1">Information you provide in your message</Typography>
        </li>
      </ul>
    </Section>
  );
}

export default PrivacyPolicy;
